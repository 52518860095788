import React from "react";
import { PageLayout, Background } from "@bluefin/components";
import { Grid, Header, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class EventsPage extends React.PureComponent {
  render() {
    return (
      <WebsiteLayout>
        <PageLayout
          hero={
            <Background
              columns={1}
              stackable={true}
              className={"events-hero"}
            />
          }
          subfooter={false}
          className={"events-page"}
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={14} textAlign={"center"}>
              <Header as={"h1"}>Events</Header>
              <Grid stackable={true} verticalAlign={"middle"} columns={"3"}>
                <Grid.Column width={5}>
                  <Card>
                    <Card.Content>
                      <Card.Header>Trivia</Card.Header>
                    </Card.Content>
                    <Card.Content extra={true}>
                      <span>Tuesdays (8:00 pm)</span>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column>
                  <Card width={5}>
                    <Card.Content>
                      <Card.Header>Tipsy Chess</Card.Header>
                    </Card.Content>
                    <Card.Content extra={true}>
                      <span>1st Monday of every month (6:30 pm)</span>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column width={5}>
                  <Card>
                    <Card.Content>
                      <Card.Header>Comedian Open Mic</Card.Header>
                    </Card.Content>
                    <Card.Content extra={true}>
                      <span>Thursdays (5:30 pm)</span>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column width={5}>
                  <Card>
                    <Card.Content>
                      <Card.Header>Comedy Saturday Show</Card.Header>
                    </Card.Content>
                    <Card.Content extra={true}>
                      <span>The schedule is to be announced</span>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column width={5}>
                  <Card>
                    <Card.Content>
                      <Card.Header>Lectures on Tap</Card.Header>
                    </Card.Content>
                    <Card.Content extra={true}>
                      <span>Coming Soon</span>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column width={5}>
                  <Card>
                    <Card.Content>
                      <Card.Header>Music Bingo</Card.Header>
                    </Card.Content>
                    <Card.Content extra={true}>
                      <span>Coming Soon</span>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}
